<template>
  <Modal data-bs-backdrop="static"
         data-bs-keyboard="false"
         id="project-form-modal"
         ref="projectModal">
    <ModalHeader @modalClosed="hideModal">
      <ModalTitle :title="$t('form.project_form')"></ModalTitle>
    </ModalHeader>
    <Form @submit="storeProject"
          :initial-values="instance"
          :validation-schema="schema"
          id="project-form"
          ref="projectForm">
      <ModalBody class="pt-0">
        <FormInput type="text" name="title" :label="$t('form.project_title')" required></FormInput>
        <FormInput type="date" name="start_date" :label="$t('form.start_date')" v-on:change="setDate"
                   required></FormInput>
        <FormInput type="number" name="date_range" :label="$t('form.date_range')"
                   v-on:change="dateRangeChanged"></FormInput>
        <FormInput type="date" name="end_date" :label="$t('form.end_date')" required></FormInput>
        <div class="form-check" v-for="employee in employees" :key="employee.id">
          <Field name="employee"
                 type="checkbox"
                 class="form-check-input"
                 :value="employee.id"/>
          <label class="form-check-label">
            {{ employee.contact.first_name }} {{ employee.contact.last_name }}
          </label>
        </div>
        <button class="btn btn-primary" type="submit">
          {{ $t('text.save') }}
        </button>
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </Form>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ModalBody from '@/components/modal/ModalBody.vue'
import Modal from '@/components/modal/Modal.vue'
import ModalHeader from '@/components/modal/ModalHeader.vue'
import ModalTitle from '@/components/modal/ModalTitle.vue'
import ModalFooter from '@/components/modal/ModalFooter.vue'
import { Field, Form } from 'vee-validate'
import { errorHelper } from '@/utils'
import * as yup from 'yup'
import FormInput from '@/components/forms/FormInput.vue'
import {
  CalendarEntryKind, CalendarEntryPayload,
  Employee,
  ProjectPayload
} from '@/types/descript_kundenverwaltung_rest'
import { MAX_NUMBER_OF_DAYS } from '@/types/internal'
import dayjs from "dayjs"

const emptyProjectInstance = {
        id: -1,
        title: '',
        start_date: '' as string|Date,
        end_date: '' as string|Date,
        employee: [] as Array<number>|null
      }

export default defineComponent({
  name: 'ProjectModal',
  computed: {
    employees: function (): Array<Employee> {
      return this.$store.state.employees
    },
  },
  components: {
    Field,
    Form,
    FormInput,
    ModalBody,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
  },
  data : function () {
    const schema = yup.object().shape({
      title: yup.string().required(this.$t('form_validation.required')),
      start_date: yup.date().required(this.$t('form_validation.required')),
      date_range: yup.number().max(MAX_NUMBER_OF_DAYS, this.$t('form_validation.too_large')),
      end_date: yup.date().required(this.$t('form_validation.required')),
    })
    return {
      start_date: '' as string,
      end_date: '',
      date_range: 0,
      schema: schema,
      instance: emptyProjectInstance
    }
  },
  setup () {
    // refs to child components in a TypeScript compatible way,
    // see https://v3.vuejs.org/guide/typescript-support.html#typing-refs.
    const projectModal = ref<InstanceType<typeof Modal>>()
    return { projectModal }
  },
  methods: {
    show : function () {
      if (this.projectModal) {
        const projectFormRef = this.$refs.projectForm as typeof Form
        projectFormRef.setFieldValue('start_date', this.start_date.substring(0, 10))
        this.projectModal.show()
      }
    },
    hideModal () {
      this.resetForm()
      if (this.projectModal){
        this.projectModal.hide()
      }
    },
    resetForm() {
      const projectFormRef = this.$refs.projectForm as typeof Form
      this.instance = emptyProjectInstance
      this.date_range = 0
      this.start_date = ''
      this.end_date = ''
      projectFormRef.resetForm()
    },
    storeProject: async function (values: Record<string, any>) {
      const startDate = dayjs(values["start_date"]).format()
      const endDate = dayjs(values["end_date"]).endOf("day").format()
      const data: ProjectPayload = {
        id: this.instance.id,  // taking id from instance, because the initial form data is not updated when the instance updates from outside this component
        start_date: startDate,
        end_date: endDate,
        title: values["title"]
      }
      let responseData
      if (this.instance.id > 0) {
        responseData = await this.$store.dispatch('updateProject', data)
          .catch(errorHelper(this.$store))
      }
      else {
        responseData = await this.$store.dispatch('createProject', data)
          .catch(errorHelper(this.$store))
      }
      if (values['employee'].length > 0 ){
        for (const employeeId of values['employee']) {
                const postdata: CalendarEntryPayload = {
                  id: 0, // new instance
                  start_date: startDate,
                  end_date: endDate,
                  kind: CalendarEntryKind.student_working,
                  employee: employeeId,
                  project: responseData.id
                }
          await this.$store.dispatch('createCalendarEntry', postdata)
            .catch(errorHelper(this.$store))
        }
      }
      this.$emit('projectModalSaved')
      this.hideModal()
    },
    setDate (input: any){
      input.target.value
      this.start_date=input.target.value
    },
    dateRangeChanged(input: any){
      this.date_range = input.target.value
    },
    calculateEnd: async function (dateRange: number, startDate: string) {
      const response_data = await this.$store.dispatch('getEndDate', {
        'start_date': new Date(startDate),
        'date_range': dateRange
      }).catch(errorHelper(this.$store))
      const projectFormRef = this.$refs.projectForm as typeof Form
      this.end_date = response_data['end_date']
      projectFormRef.setFieldValue('end_date', new Date((new Date(this.end_date).valueOf() - 1000)).toISOString().substring(0, 10))
    }
  },
  watch:{
    date_range: function (newValue){
      if (this.start_date !== '' && this.date_range < MAX_NUMBER_OF_DAYS) {
        this.calculateEnd(newValue, this.start_date)
      }
    },
    start_date: function (newValue){
      if (this.date_range > 0 && this.date_range < MAX_NUMBER_OF_DAYS) {
        this.calculateEnd(this.date_range, newValue)
      }
    }
  },
  emits: ['projectModalSaved']
})
</script>

<style scoped>

</style>
