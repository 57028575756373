<template>
  <main class="container py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="card" v-bind:class=cardStyle>
          <div class="card-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer/>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Footer from '@/components/base/Footer.vue'

export default defineComponent({
  name: 'MainCard',
  components: {
    Footer
  },
  props: {
    cardStyle: {
      type: String
    }
  }
})
</script>

<style scoped>
</style>
