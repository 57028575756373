import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-grid gap-2 d-md-flex" }
const _hoisted_3 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalTitle = _resolveComponent("ModalTitle")!
  const _component_ModalHeader = _resolveComponent("ModalHeader")!
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Form = _resolveComponent("Form")!
  const _component_ModalBody = _resolveComponent("ModalBody")!
  const _component_ModalFooter = _resolveComponent("ModalFooter")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "data-bs-backdrop": "static",
    "data-bs-keyboard": "false",
    id: "calendar-entry-form-modal",
    ref: "calendarEntryModal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ModalHeader, { onModalClosed: _ctx.hideModal }, {
        default: _withCtx(() => [
          _createVNode(_component_ModalTitle, {
            title: _ctx.$t('form.calendar_entry_form')
          }, null, 8, ["title"])
        ]),
        _: 1
      }, 8, ["onModalClosed"]),
      _createVNode(_component_ModalBody, { class: "pt-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_Form, {
            onSubmit: _ctx.storeCalendarEntry,
            "initial-values": _ctx.instance,
            "validation-schema": _ctx.schema,
            id: "calendar-entry-form",
            ref: "calendarEntryForm"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormSelect, {
                options: _ctx.employeeChoices,
                name: "employee"
              }, null, 8, ["options"]),
              _createVNode(_component_FormSelect, {
                options: _ctx.kindChoices,
                name: "kind",
                onChange: _ctx.kindChoiceChanged
              }, null, 8, ["options", "onChange"]),
              (_ctx.instance.kind == 'student_working')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_FormSelect, {
                      options: _ctx.projectChoices,
                      name: "project",
                      onChange: _ctx.projectChoiceChanged
                    }, null, 8, ["options", "onChange"]),
                    (_ctx.instance.project == 'new_project')
                      ? (_openBlock(), _createBlock(_component_FormInput, {
                          key: 0,
                          name: "project_name",
                          label: _ctx.$t('form.project_title'),
                          required: ""
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_FormInput, {
                type: "date",
                name: "start_date",
                label: _ctx.$t('form.start_date'),
                onChange: _ctx.setDate,
                required: ""
              }, null, 8, ["label", "onChange"]),
              _createVNode(_component_FormInput, {
                type: "number",
                name: "date_range",
                label: _ctx.$t('form.date_range'),
                onChange: _ctx.dateRangeChanged
              }, null, 8, ["label", "onChange"]),
              _createVNode(_component_FormInput, {
                type: "date",
                name: "end_date",
                label: _ctx.$t('form.end_date'),
                required: ""
              }, null, 8, ["label"]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.$t('text.save')), 1),
                (_ctx.instance.id > 0)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "btn btn-secondary",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeEntry && _ctx.removeEntry(...args)))
                    }, _toDisplayString(_ctx.$t('text.remove')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["onSubmit", "initial-values", "validation-schema"])
        ]),
        _: 1
      }),
      _createVNode(_component_ModalFooter)
    ]),
    _: 1
  }, 512))
}