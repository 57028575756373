<template>
  <router-view/>
  <div class="position-fixed bottom-0 end-0 p-3 z-index-toast">
    <div id="updateToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">{{ $t('text.update_available') }}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        <p>{{ $t('text.reload_site') }}</p>
        <Button btn-style="btn-sm btn-secondary border-0" btn-type="button" v-on:click="reloadSite">
          {{ $t('text.reload') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Toast } from 'bootstrap'
import Button from '@/components/base/Button.vue'

export default defineComponent({
  name: 'App',
  components: {
    Button
  },
  data: function () {
    return {
      toast: null as Toast | null
    }
  },
  mounted: function () {
    const localToast = document.querySelectorAll('#updateToast')[0]
    this.toast = new Toast(localToast, { autohide: false })
    window.addEventListener('vueVersionUpdated', () => {
      if (this.toast !== null) {
        this.toast.show()
      }
    })
  },
  methods: {
    reloadSite () {
      window.location.reload()
    },
  },
  unmounted: function () {
    if (this.toast !== null) {
      this.toast.dispose()
    }
  }
})
</script>

<style>

</style>
