import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-check-label" }
const _hoisted_2 = {
  class: "btn btn-primary",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalTitle = _resolveComponent("ModalTitle")!
  const _component_ModalHeader = _resolveComponent("ModalHeader")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ModalBody = _resolveComponent("ModalBody")!
  const _component_ModalFooter = _resolveComponent("ModalFooter")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "data-bs-backdrop": "static",
    "data-bs-keyboard": "false",
    id: "project-form-modal",
    ref: "projectModal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ModalHeader, { onModalClosed: _ctx.hideModal }, {
        default: _withCtx(() => [
          _createVNode(_component_ModalTitle, {
            title: _ctx.$t('form.project_form')
          }, null, 8, ["title"])
        ]),
        _: 1
      }, 8, ["onModalClosed"]),
      _createVNode(_component_Form, {
        onSubmit: _ctx.storeProject,
        "initial-values": _ctx.instance,
        "validation-schema": _ctx.schema,
        id: "project-form",
        ref: "projectForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ModalBody, { class: "pt-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_FormInput, {
                type: "text",
                name: "title",
                label: _ctx.$t('form.project_title'),
                required: ""
              }, null, 8, ["label"]),
              _createVNode(_component_FormInput, {
                type: "date",
                name: "start_date",
                label: _ctx.$t('form.start_date'),
                onChange: _ctx.setDate,
                required: ""
              }, null, 8, ["label", "onChange"]),
              _createVNode(_component_FormInput, {
                type: "number",
                name: "date_range",
                label: _ctx.$t('form.date_range'),
                onChange: _ctx.dateRangeChanged
              }, null, 8, ["label", "onChange"]),
              _createVNode(_component_FormInput, {
                type: "date",
                name: "end_date",
                label: _ctx.$t('form.end_date'),
                required: ""
              }, null, 8, ["label"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employees, (employee) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "form-check",
                  key: employee.id
                }, [
                  _createVNode(_component_Field, {
                    name: "employee",
                    type: "checkbox",
                    class: "form-check-input",
                    value: employee.id
                  }, null, 8, ["value"]),
                  _createElementVNode("label", _hoisted_1, _toDisplayString(employee.contact.first_name) + " " + _toDisplayString(employee.contact.last_name), 1)
                ]))
              }), 128)),
              _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.$t('text.save')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ModalFooter)
        ]),
        _: 1
      }, 8, ["onSubmit", "initial-values", "validation-schema"])
    ]),
    _: 1
  }, 512))
}