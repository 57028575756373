<template>
  <Alerts/>
  <div class="d-flex justify-content-center border-bottom p-2">
    <div class="flex-grow-1 text-start">
      <a class="btn btn-light btn-sm" @click="pastMonth">
        <b-icon-arrow-left></b-icon-arrow-left> Vorheriger Monat
      </a>
      <a class="btn btn-light btn-sm ms-2" @click="minusOneWeek">
        <b-icon-arrow-left></b-icon-arrow-left> Woche zurück
      </a>
    </div>
    <div class="flex-grow-1 text-center">
      <a class="btn btn-secondary btn-sm" @click="gotoCurrentWeek">
        Aktuelle Woche
      </a>
    </div>
    <div class="flex-grow-1 text-end">
      <a class="btn btn-light btn-sm" @click="plusOneWeek">
        Woche vor <b-icon-arrow-right></b-icon-arrow-right>
      </a>
      <a class="btn btn-light btn-sm ms-2" @click="nextMonth">
        Nächster Monat <b-icon-arrow-right></b-icon-arrow-right>
      </a>
    </div>
  </div>
  <g-gantt-chart
    v-if="!isLoading"
    :chart-start="chartStart"
    :chart-end="chartEnd"
    precision="day"
    :row-height="40"
    grid
    width="100%"
    bar-start="beginDate"
    bar-end="endDate"
    :date-format="format"
    label-column-title="&nbsp;"
    @click-bar="onMouseupBar($event.bar, $event.e, $event.datetime)"
    @mousedown-bar="onMousedownBar($event.bar, $event.e, $event.datetime)"
    @dblclick-bar="onClickBar($event.bar, $event.e, $event.datetime)"
    @mouseenter-bar="onMouseenterBar($event.bar, $event.e)"
    @mouseleave-bar="onMouseleaveBar($event.bar, $event.e)"
    @dragstart-bar="onDragstartBar($event.bar, $event.e)"
    @drag-bar="onDragBar($event.bar, $event.e)"
    @dragend-bar="onDragendBar($event.bar, $event.e, $event.movedBars)"
    @contextmenu-bar="onContextmenuBar($event.bar, $event.e, $event.datetime)"
  >
    <g-gantt-row v-for="employee in employees"
                 :label="getEmployeeName(employee)"
                 :key="employee.id"
                 :bars="bars[employee.id]"
                 highlight-on-hover
                 @clicked-time="newEntry($event.e, $event.value, employee)"
    />
    <g-gantt-row :label="$t('text.open_construction_sites')"
                 :bars="openProjectsBars"
                 highlight-on-hover
                 @clicked-time="newProject($event.e, $event.value)"
    />
  </g-gantt-chart>
  <ProjectModal
    ref="projectModal"
    size-class="modal-lg"
    :employees="employees"
    @project-modal-saved="updateBoard"></ProjectModal>
  <CalendarEntryModal
    ref="calendarEntryModal"
    size-class="modal-lg"
    :employees="employees"
    @calendar-entry-modal-saved="updateBoard"></CalendarEntryModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  CalendarEntry,
  CalendarEntryKind,
  Employee,
  Project
} from '@/types/descript_kundenverwaltung_rest'
import { errorHelper } from '@/utils'
import { ref } from "vue"
import type { GanttBarObject } from '@/ganttastic/types'
import { GGanttChart, GGanttRow } from '../ganttastic/vue-ganttastic'
import dayjs, { Dayjs } from 'dayjs'
import { i18n } from '@/i18n'
import Alerts from '@/components/base/Alerts.vue'
import ProjectModal from '@/components/scheduling_board_interactions/ProjectModal.vue'
import CalendarEntryModal from '@/components/scheduling_board_interactions/CalendarEntryModal.vue'
import { chartSize } from '@/config/config'
import { isWeekday } from '@/ganttastic/composables/useDayjsHelper'

const format = ref("YYYY-MM-DD HH:mm:ss")


export default defineComponent({
  name: 'SchedulingBoardView',
  components: {
    CalendarEntryModal,
    ProjectModal,
    Alerts,
    GGanttChart,
    GGanttRow
  },
  setup () {
    // refs to child components in a TypeScript compatible way,
    // see https://v3.vuejs.org/guide/typescript-support.html#typing-refs.
    const projectModal = ref<InstanceType<typeof ProjectModal>>()
    const calendarEntryModal = ref<InstanceType<typeof CalendarEntryModal>>()

    return { projectModal, calendarEntryModal }
  },
  data: function () {
    const { start, end } = this.getChartDimension(dayjs())
    return {
      format: format,
      chartStart: ref(start.format(format.value)),
      chartEnd: ref(end.format(format.value)),
      bars: {} as Record<number, Array<GanttBarObject>>,
      openProjectsBars: [] as Array<GanttBarObject>,
      isLoading: true
    }
  },
  computed: {
    employees: function (): Array<Employee> {
      return this.$store.state.employees
    },
    calendarEntries: function (): Array<CalendarEntry> {
      return this.$store.state.calendarEntries
    },
    projects: function (): Array<Project> {
      return this.$store.state.projects
    }
  },
  created: async function () {
    Promise.all(
      [
        await this.$store.dispatch('getEmployees').catch(errorHelper(this.$store)),
        await this.$store.dispatch('getCalendarEntries').catch(errorHelper(this.$store)),
        await this.$store.dispatch('getProjects').catch(errorHelper(this.$store)),
      ]
    ).then(() => {
      this.isLoading = false
    })
    this.createBarsForEmployees()
    this.createOpenProjectsBars()
  },
  methods: {
    getChartDimension (date: Dayjs) {
      date = date.weekday(0) // Chart should always start on monday.
      return {
        start: date.startOf("date"),
        end: date.startOf("date").add(chartSize - 1, "days").endOf("date")
      }
    },
    getFirstOfCurrentChartMonth () {
      const currentChartStart = dayjs(this.chartStart)
      const secondWeekStart = dayjs(this.chartStart).add(7, "days")

      let firstOfCurrentChartMonthString = currentChartStart.year().toString() + '-'

      // The current chart month might not be the month of this.chartStart.
      // Charts always start Monday and this means when the first day of a
      // month is for example Tuesday and the chart should jump to this
      // month it actually jumps to the last day of the month before to
      // still start on a Monday.
      if (currentChartStart.month() != secondWeekStart.month()) {
        firstOfCurrentChartMonthString = firstOfCurrentChartMonthString + (secondWeekStart.month() + 1).toString()
      } else {
        firstOfCurrentChartMonthString = firstOfCurrentChartMonthString + (secondWeekStart.month() + 1).toString()
      }

      firstOfCurrentChartMonthString = firstOfCurrentChartMonthString + '-' + '01'

      return dayjs(firstOfCurrentChartMonthString)
    },
    pastMonth () {
      const firstOfCurrentChartStartMonth = this.getFirstOfCurrentChartMonth()
      const newDate = firstOfCurrentChartStartMonth.subtract(1, "months")
      const { start, end } = this.getChartDimension(newDate)
      this.chartStart = start.format(format.value)
      this.chartEnd = end.format(format.value)
    },
    minusOneWeek () {
      const newDate = dayjs(this.chartStart).subtract(7, "days")
      const { start, end } = this.getChartDimension(newDate)
      this.chartStart = start.format(format.value)
      this.chartEnd = end.format(format.value)
    },
    gotoCurrentWeek () {
      const { start, end } = this.getChartDimension(dayjs())
      this.chartStart = start.format(format.value)
      this.chartEnd = end.format(format.value)
    },
    plusOneWeek () {
      const newDate = dayjs(this.chartStart).add(7, "days")
      const { start, end } = this.getChartDimension(newDate)
      this.chartStart = start.format(format.value)
      this.chartEnd = end.format(format.value)
    },
    nextMonth () {
      const firstOfCurrentChartStartMonth = this.getFirstOfCurrentChartMonth()
      const newDate = firstOfCurrentChartStartMonth.add(1, "months")
      const { start, end } = this.getChartDimension(newDate)
      this.chartStart = start.format(format.value)
      this.chartEnd = end.format(format.value)
    },
    getEmployeeName (employee: Employee){
      return `${employee.contact.first_name} ${employee.contact.last_name}`
    },
    createBarsForEmployees (){
      for (const employee of this.employees) {
        this.bars[employee.id] = this.createBarsForEmployee(employee)
      }
    },
    getNameOfProject (projectId: number): string {
      for (const project of this.projects) {
        if (project.id === projectId) {
          return project.title
        }
      }

      return projectId.toString()
    },
    getLabelForBar (calendarEntry: CalendarEntry) {
      if (calendarEntry.project) {
        return this.getNameOfProject(calendarEntry.project)
      }

      if (
        calendarEntry.kind === CalendarEntryKind.sickness
        || calendarEntry.kind === CalendarEntryKind.sickness_without_certificate
        || calendarEntry.kind === CalendarEntryKind.sickness_child
      ) {
        // Do not reveal exact type of sickness in the scheduling board.
        return i18n.global.t('calendar_entry_type.' + CalendarEntryKind.sickness)
      }
      return i18n.global.t('calendar_entry_type.' + calendarEntry.kind)
    },
    getClassForBar (calendarEntry: CalendarEntry): string {
      const cssClasses: Array<string> = []

      if (
           calendarEntry.kind === CalendarEntryKind.sickness
        || calendarEntry.kind === CalendarEntryKind.sickness_without_certificate
        || calendarEntry.kind === CalendarEntryKind.sickness_child
      ) {
        cssClasses.push('bg-danger bg-opacity-25 border border-danger rounded-1')
      } else if (calendarEntry.kind == CalendarEntryKind.workshop) {
        cssClasses.push('bg-warning bg-opacity-25 border border-warning rounded-1')
      } else if (
           calendarEntry.kind === CalendarEntryKind.holiday
        || calendarEntry.kind == CalendarEntryKind.freetime_compensation
      ) {
        cssClasses.push('bg-success bg-opacity-25 border border-success rounded-1')
      } else {
        cssClasses.push('bg-secondary bg-opacity-25 border border-secondary rounded-1')
      }

      return cssClasses.join(' ')
    },
    createBarForEmployee (employee: Employee, calendarEntry: CalendarEntry) {
      return {
        beginDate: dayjs(calendarEntry.start_date).format(format.value),
        endDate: dayjs(calendarEntry.end_date).format(format.value),
        ganttBarConfig: {
          id: 'calendar_entry::' + calendarEntry.id.toString(), // use dis is schöner
          label: this.getLabelForBar(calendarEntry),
          hasHandles: true,
          class: this.getClassForBar(calendarEntry),
          representedObject: calendarEntry
        }
      }
    },
    createBarsForEmployee (employee: Employee) {
      const employeeBars: Array<GanttBarObject> = []
      for (const calendarEntry of this.calendarEntries) {
        if (employee.id === calendarEntry.employee) {
          employeeBars.push(this.createBarForEmployee(employee, calendarEntry))
        }
      }
      return employeeBars
    },
    updateBoard: async function() {
      await this.$store.dispatch('getCalendarEntries').catch(errorHelper(this.$store))
      await this.$store.dispatch('getProjects').catch(errorHelper(this.$store))
      this.createBarsForEmployees()
      this.createOpenProjectsBars()
    },
    createOpenProjectsBars () {
      const nonOpenProjects: Array<number> = []
      for (const calendarEntry of this.calendarEntries) {
        if (calendarEntry.project && !nonOpenProjects.includes(calendarEntry.project)) {
          nonOpenProjects.push(calendarEntry.project)
        }
      }

      const openProjectsBars : Array<GanttBarObject> = []
      for (const project of this.projects) {
        if (!nonOpenProjects.includes(project.id) && project.start_date && project.end_date) {
          openProjectsBars.push(
            {
              beginDate: dayjs(project.start_date).format(format.value),
              endDate: dayjs(project.end_date).format(format.value),
              ganttBarConfig: {
                id: 'project::' + project.id.toString(),
                label: project.title,
                hasHandles: true,
                class: 'bg-info bg-opacity-25 border border-info rounded-1',
                representedObject: project
              }
            }
          )
        }
      }

      this.openProjectsBars = openProjectsBars
    },
    properDate: function (value: string | Date) {
      return dayjs(value).format()
    },
    newProject: async function (e: MouseEvent, value: string | Date) {
      const date = dayjs(value)
      if (!isWeekday(date)) {
        return
        // todo notify user? "no starting on week end"
      }
      if (this.projectModal) {
        this.projectModal.start_date = date.format()
      }
      this.showProjectModal()
    },
    newEntry: async function (e: MouseEvent, value: string | Date, employee: Employee) {
      const date = dayjs(value)
      if (!isWeekday(date)) {
        return
        // todo notify user? "no starting on week end"
      }
      if (this.calendarEntryModal) {
        this.calendarEntryModal.start_date = date.format()
      }
      this.showCalendarEntryModal(employee.id)
    },
    showProjectModal: function () {
      if (this.projectModal) {
        this.projectModal.show()
      }
    },
    showCalendarEntryModal: function (employeeId?: number) {
      if (this.calendarEntryModal) {
        this.calendarEntryModal.show(employeeId)
      }
    },
    onClickBar: async function (bar: GanttBarObject, e: MouseEvent, datetime?: string | Date) {
      {
        console.debug("onClickBar", bar, e, datetime)

        // todo: this can be refactored, the GanttBarObject now contains the server-side object, the double colon split shenanigans is no longer needed.
        const barId = bar.ganttBarConfig.id.split('::')
        if (barId[0] === 'project') {
          let currentProject = null
          for (const project of this.projects) {
            if (project.id.toString() === barId[1]) {
              currentProject = project
              break
            }
          }
          if (this.projectModal && currentProject != null && currentProject.start_date && currentProject.end_date) {
            const date_range_response_data = await this.$store.dispatch('getDateRange', {start_date: currentProject.start_date, end_date: currentProject.end_date}).catch(errorHelper(this.$store))
            let projectFormInstance = {
              id: currentProject.id,
              title: currentProject.title,
              start_date: this.properDate(currentProject.start_date).substring(0, 10),
              end_date: this.properDate(currentProject.end_date).substring(0, 10),
              date_range: date_range_response_data['date_range'] + 1,
              employee: []
            }
            this.projectModal.start_date = this.properDate(this.properDate(currentProject.start_date))
            this.projectModal.end_date = this.properDate(this.properDate(currentProject.end_date))
            this.projectModal.instance = projectFormInstance
          }
          this.showProjectModal()
        }
        else if (barId[0] === 'calendar_entry') {
          let current_calendar_entry = null
          for (const entry of this.calendarEntries) {
            if (entry.id.toString() === barId[1]) {
              current_calendar_entry = entry
              break
            }
          }
          if (this.calendarEntryModal && current_calendar_entry != null && current_calendar_entry.start_date && current_calendar_entry.end_date) {
              let date_range_response_data = await this.$store.dispatch('getDateRange', {
                start_date: current_calendar_entry.start_date,
                end_date: current_calendar_entry.end_date
              }).catch(errorHelper(this.$store))
              let entryFormInstance = {
                id: current_calendar_entry.id,
                employee: current_calendar_entry.employee,
                kind: current_calendar_entry.kind,
                start_date: this.properDate(current_calendar_entry.start_date).substring(0, 10),
                end_date: this.properDate(current_calendar_entry.end_date).substring(0, 10),
                date_range: date_range_response_data['date_range'] + 1,
                // hacky, but whole modal construction is wierd, this data should be in props
                project: current_calendar_entry.project ? current_calendar_entry.project : "new_project"
              }
            this.calendarEntryModal.start_date = this.properDate(this.properDate(current_calendar_entry.start_date))
            this.calendarEntryModal.end_date = this.properDate(this.properDate(current_calendar_entry.end_date))
            this.calendarEntryModal.instance = entryFormInstance
            this.showCalendarEntryModal()
          }
        }
      }
    },
    onMousedownBar (bar: GanttBarObject, e: MouseEvent, datetime?: string | Date) {
      console.debug('onMousedownBar', bar, e, datetime)
    },
    onMouseupBar: async function (bar: GanttBarObject, e: MouseEvent, datetime?: string | Date) {
      console.debug('onMouseupBar', bar, e, datetime)
    },
    onMouseenterBar (bar: GanttBarObject, e: MouseEvent) {
      console.debug('onMouseenterBar', bar, e)
    },
    onMouseleaveBar (bar: GanttBarObject, e: MouseEvent) {
      console.debug('onMouseleaveBar', bar, e)
    },
    onDragstartBar (bar: GanttBarObject, e: MouseEvent) {
      console.debug('onDragstartBar', bar, e)
    },
    onDragBar (bar: GanttBarObject, e: MouseEvent) {
      console.debug('onDragBar', bar, e)
    },
    onDragendBar (bar: GanttBarObject, e: MouseEvent, movedBars?: Map<GanttBarObject, {
      oldStart: string;
      oldEnd: string
    }>) {
      console.debug('onDragendBar', bar, e, movedBars)

      if (movedBars !== undefined && movedBars.has(bar) ) {
        const movedBar = movedBars.get(bar)
        if (movedBar && movedBar.oldStart === bar.beginDate && movedBar.oldEnd === bar.endDate) {
          // Nothing has changed.
          return
        }

        if (bar.ganttBarConfig.representedObject instanceof CalendarEntry) {
          this.$store.dispatch(
            "updateCalendarEntry",
            {
              id: bar.ganttBarConfig.representedObject.id,
              start_date: dayjs(bar.beginDate).format(),
              end_date: dayjs(bar.endDate).format(),
            }
          ).then(() => {
            // This is somewhat inefficient, but we need to update the reference object
            // in the "representedObject" attribute for every bar.
            this.createBarsForEmployees()
          }).catch(errorHelper(this.$store))
        } else { // is Project
          this.$store.dispatch(
            "updateProject",
            {
              id: bar.ganttBarConfig.representedObject.id,
              start_date: dayjs(bar.beginDate).format(),
              end_date: dayjs(bar.endDate).format(),
            }
          ).then(() => {
            // This is somewhat inefficient, but we need to update the reference object
            // in the "representedObject" attribute for every bar.
            this.createOpenProjectsBars()
          }).catch(errorHelper(this.$store))
        }
      } else {
        // This handler should always be called with parameter 'movedBars', we do
        // nothing when it is undefined.
        console.warn("Handler 'onDragendBar' was called without parameter 'movedBars'.")
        return
      }
    },
    onContextmenuBar (bar: GanttBarObject, e: MouseEvent, datetime?: string | Date) {
      console.debug('onContextmenuBar', bar, e, datetime)
    },
    resetBar (barId: string) {
      for (let k in this.bars) {
        this.bars[k].forEach(
          (el) => {
            if (el.ganttBarConfig.id == barId) {
              el.beginDate = el.ganttBarConfig.representedObject.start_date
              el.endDate = el.ganttBarConfig.representedObject.end_date
            }
          })
      }
    }
  }
})
</script>
