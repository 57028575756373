import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container py-5" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-12 col-md-8 col-lg-6" }
const _hoisted_4 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["card", _ctx.cardStyle])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}